.guide {
    color: #e6e6e6;
    font-family: Arial, sans-serif;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
}

.guide-section {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.guide-content {
    display: flex;
    width: 100%;
}

.guide-image {
    margin-right: 25px;
    border-radius: 8px;
}

.guide-text {
    flex: 1;
}

section h2 {
    font-size: 1.4em;
    margin-bottom: 8px;
    color: #ffffff;
}

section p {
    color: #e6e6e6;
    font-size: 1em;
    margin: 0;
}

.guide-heading {
    color: #d3c831;
}

.guide {
    color: #e6e6e6;
    font-family: Arial, sans-serif;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
}

.guide-section {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.guide-content {
    display: flex;
    width: 100%;
}

.guide-image {
    margin-top: 5px;
    max-width: 560px;
    height: 190px;
    margin-right: 25px;
    border-radius: 8px;
}

.guide-text {
    flex: 1;
}

section h2 {
    font-size: 1.4em;
    margin-bottom: 8px;
    color: #ffffff;
}

section p {
    color: #e6e6e6;
    font-size: 1em;
    margin: 0;
}

.guide-heading {
    color: #d3c831;
}

@media (max-width: 600px) {
    .guide {
        padding: 10px;
        max-width: 100%;
    }

    .guide-section {
        flex-direction: column;
        align-items: center;
    }

    .guide-content {
        flex-direction: column;
        align-items: center;
    }

    .guide-image {
        max-width: 300px;
        margin: 0 0 10px 0;
    }

    section h2 {
        font-size: 1.2em;
    }

    section p {
        font-size: 0.9em;
    }
}
