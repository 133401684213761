.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.forgot-password-form {
    width: 350px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.forgot-password-form h2 {
    margin-bottom: 20px;
}

.forgot-password-form p {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error-message, .success-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
}

.error-message {
    color: #ff0000;
    background-color: #ffe6e6;
}

.success-message {
    color: #008000;
    background-color: #e6ffe6;
}

button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}
