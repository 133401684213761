.photoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color:#0B1F31;
}

.photoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #276394;
    opacity: 68%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.3s ease;
}
.yellow-line {
    width: 300px; 
    height: 3px; 
    background-color: #DAC625; 
    margin: 10px auto;
  }
.photoItem img {
    width: 100%;
    max-height: 150px;
    /* object-fit: cover; */
    border-radius: 5px;
}

.photoItem p {
    margin-top: 10px;
    font-size: 35px;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 1px;
}

.photoItem:hover {
    transform: scale(1.1);
}
.overlay-new {
    margin-top: 20px;
    display: ruby-text;
}
.largerText {
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
.smallerText {
    font-size: 20px;
    color: #fff;
    text-align: center;
  }

.novi .logo-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.rights-container {
    text-align: center;
}
  
.rights-text {
    margin-top: 20px;
}
  
.logo-img {
    max-width: 100px;
    height: auto;
    margin-bottom: 20px;
  }
@media screen and (max-width: 768px) {
    .photoGrid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    .largerText {
        padding: 0 10px;
      }
    
      .smallerText {
        padding: 0 10px;
        text-align: center;
      }
      .photoItem p {
        margin-top: 10px;
        font-size: 16px;
        color: #fff;
    }
}
