
.slidecontainer{
    width: 80%;
    height: 100px;
    position: relative;
    margin-bottom: 50px;
}
.report_time{
    position: absolute;
    height: 100px;
    width: 1px;
    top: 0;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}
.report_time>p{
    cursor: pointer;
}

.report_time_link {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #dac625;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.report_time_link>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #0B1F31;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.coverage{
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e5eff7;
    padding: 50px 10px 30px 10px;
}
.coverage_info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top:30px;
}

.coverage_info_percent{
    border: 1px solid #0B1F31;
    padding: 45px 35px 45px 35px;
    font-size: 26px;
    font-family: "Varela Round", sans-serif;
    border-radius: 5px;
    background-color: white;
    color: #0B1F31;
}
.coverage_info_percent>.coverage_info_percent_data{
    font-size: 42px;
    font-weight: normal;
    font-family:  sans-serif;
    text-align: center;
    text-decoration: underline;
    color: #ec0ea9;
    
}
.coverage_info_legend{
    border: 1px solid #0B1F31;
    padding: 30px 35px 30px 35px;
    font-size: 26px;
    font-family: "Varela Round", sans-serif;
    border-radius: 5px;
    background-color: white;
    color: #0B1F31;
}
.coverage_info_legend>p{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}
.coverage_info_legend>p>span{
    display: inline-block;
    width: 62px;
    height:48px;
    margin-right: 5px;
    border-radius: 5px;
}
.coverage_info_legend>p>.uncovered{
    background-color: #cef5f4;
}

.coverage_info_legend>p>.covered{
    background-color: #ADD8E6;
}

@media screen and (max-width: 720px) {
    .slidecontainer {
      overflow-x: scroll;
      overflow-y: visible;
      width: 80%;
    }
    .coverage_info{
        flex-direction: column;
        gap: 10px;
    }
  }

  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.biofouling-heatmap-report>h1 {
    font-family: "Varela Round", sans-serif;
    font-size: 5rem;
    width: 100%;
}

.biofouling-heatmap-report>p {
    font-family: "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; 
    font-stretch: condensed;
}


.biofouling-heatmap-report{
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0B1F31;


}
.biofouling-heatmap-report .heatmap {
    padding: 10px;
    border-radius: 15px;
    position: relative;
}

#image_container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-50%) translateY(-15%);
    z-index: 10;
    box-shadow: 10px 10px 10px #0B1F31;
    width: 500px;
}

#graph_interaction {
    width: 80%;
    padding: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.biofouling-heatmap-report>.title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin-top: 10px;
    padding: 20px;
}

.biofouling-heatmap-report .reportInfo_heatmap {
    background-color: #e5eff7;
    position: relative;
    width: 95%;
    margin-bottom: 10px;
    padding: 30px 0 50px;
    box-sizing: border-box;
}

.biofouling-heatmap-report .report-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.biofouling-heatmap-report .info {
    background-color: #e5eff7;
    width: 100%;
    border-left: 2px solid white;
    padding: 5px;
}

.biofouling-heatmap-report .info p {
    text-align: center;
    color: #0B1F31;
    font-weight: 600;
}

.biofouling-heatmap-report .info-title {
    color: #046EBC;
    font-weight: 700;
}

#image {
    display: none;
}

.biofouling-heatmap-report .showImg-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
    color: #0B1F31;
    font-size: 1.3rem;
}

.biofouling-heatmap-report .toggle-switch {
    margin-left: 10px;
}

/* Hide the default checkbox */
.reportInfo_heatmap .toggle-switch  input {
    display: none;
}

/* Style the slider (toggle button) */
.reportInfo_heatmap .slider {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.4s;
    cursor: pointer;
}

/* Style the slider handle (the toggle indicator) */
.reportInfo_heatmap .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s;
}

/* Change background color of slider when checked */
.reportInfo_heatmap input:checked +  .slider {
    background-color: #036fb8;
}

/* Move the toggle handle to the right when checked */
.reportInfo_heatmap input:checked +  .slider:before {
    transform: translateX(26px);
}

.biofouling-heatmap-report .title {
    text-align: center;
    padding-left: 1rem;
}


.biofouling-heatmap-report .image-info {
    width: 95%;
    display: flex;
    flex-direction: row;
}