@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Titillium Web", sans-serif;
    
}

:root {
    --mainColor: black;
    --secondaryColor: #db2b39;
    --textColor: #eee;
    --highlightColor: #DAC625;
}

body {
    background-color: #0B1F31;
    color: var(--mainColor);
    overflow-x: hidden;
    padding-bottom: 100px;
}

.novi {
    background-color: transparent;
    color: white;
    padding: 20px 0;
    text-align: center;
    bottom: 0;
    width: 100%;
}

.footer .logo-container {
    margin-bottom: 20px;
}

.footer .logo-container a {
    display: inline-block;
}

.footer .bluedatab-logo {
    width: 150px;
}

.footer .social-icons {
    
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer .social-icons li a {
    margin: 0 10px;
}

.footer .social-icons a {
    color: white;
    font-size: 24px;
    text-decoration: none;
}

.footer .social-icons a:hover {
    color: var(--highlightColor);
}

.footer .footer-text {
    margin-top: 20px;
    word-wrap: break-word;
}

.footer .footer-text p {
    margin: 0;
}

.footer .footer-text a {
    color: var(--highlightColor);
    text-decoration: none;
}

.footer .footer-text a:hover {
    text-decoration: underline;
}

/* Mobile */
@media (max-width: 768px) {
    .footer .social-icons {
        flex-direction: row;
        align-items: center;
    }

    .footer .footer-text {
        margin-top: 10px;
        padding: 0 10px;
        font-size: 14px;
        text-align: left;
    }
}