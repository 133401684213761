.camera-container {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.upload-progress-container-fishes{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}
.button {
  margin: 8px;
  background-color: #c90606;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    font-weight: bold;
    font-size: large;
}
.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.camera-button {
  display: block;
  background-color: #007bff; 
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  width: auto; 
  margin-bottom: 10px;
  margin-right: 10px;
}

.camera-button:disabled {
  background-color: #6c757d; 
  cursor: not-allowed;
}

.camera-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.camera-error {
  color: red;
  margin-top: 10px;
}
.video-preview {
  width: 60%;
}
.camera-video {
  width: 100%;
  height: auto;
  max-height: 400px;
  background-color: #000; 
  margin-top: 10px;
}
@media (max-width: 768px) {
  .camera-container {
    bottom: 0;
    left: 0;
    width: 90%;
    z-index:1 ;
  }
  .camera-button {
    display: block;
  }
}
.hidden {
  display: none;
}

.upload-progress-container-fishes{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.error {
  color: red;
  margin: 10px 0;
}
.thank-you-message {
  font-weight: bold;
}