.square {
    position: relative;
    cursor: pointer;
    border-radius: 10px;
}

.square.blur:hover {
    filter: blur(5px);
    pointer-events: auto;
    border-radius: 20px;
}

.square:not(.blur):hover {
    filter: none;
}
.access-message {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.app-icon {
    max-width: 100%;
    max-height: 100%;
}

.app-name {
    text-align: center;
    margin-top: 10px;
}
