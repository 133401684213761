@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Titillium Web", sans-serif;
}

:root {
    --mainColor: black;
    --secondaryColor: #db2b39;
    --textColor: #eee;
}

body {
    background-color: #0B1F31;
    overflow-x: hidden;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background-color: transparent;
    color: var(--textColor);
    transition: background-color 0.3s, color 0.3s;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

header.scrolled {
    background-color: var(--mainColor);
    color: var(--textColor);
}

.arrow-container {
    margin-right: 1rem;
}

.arrow-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    /* border: 2px solid var(--textColor); */
    border-radius: 5px;
    background: none;
    color: var(--textColor);
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}

.arrow-link:hover {
    scale: 1.2;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logo {
    height: 50px;
    transition: margin 0.3s ease;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.5s ease-in-out;
}

nav a {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    color: inherit;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    transition: color 0.3s, border-color 0.3s;
    border: 2px solid var(--textColor);
    border-radius: 5px;
    background: none;
    cursor: pointer;
}

nav a:hover {
    color: var(--secondaryColor);
    border-color: var(--secondaryColor);
}

.nav-btn {
    display: none;
}

@media only screen and (max-width: 768px) {
    .nav-btn {
        display: block;
        background: none;
        border: none;
        color: var(--textColor);
        font-size: 2rem;
        cursor: pointer;
    }

    nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        background-color: var(--mainColor);
        color: var(--textColor);
        transform: translateY(-100%);
        border-top: 2px solid var(--textColor);
    }

    .responsive_nav {
        transform: translateY(0);
    }

    .nav-close-btn {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        color: var(--textColor);
    }

    nav a {
        font-size: 1.8rem;
        padding: 1rem 1.5rem;
        border-width: 3px;
        color: var (--textColor);
    }

    nav a, .nav-btn {
        display: block;
    }
}