html, body {
    margin: 0;
    padding: 0;
    background-color: #0B1F31;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
#root {
    background-color: #0B1F31;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

/* Header CSS */
.sticky-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0B1F31;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.black-link {
    color:black;
    text-decoration: none;
}

.black-link:hover {
    text-decoration: none;
}
.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 10px 20px;
    transition: all 0.3 ease;
}

.left-section {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}

.menu-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    margin-right: 20px;
}

.logo {
    margin-right: 20px;
    display: -webkit-inline-box;
    margin-left: 20px;
}

.logo img {
    width: auto;
    height: 60px;
}

.avatar {
    width: auto;
    height: 80px;
    object-fit: cover;

}
.avatar img
 {
    width: auto;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
 }
 .yellow-line-new {
    background-color: #dac625;
    height: 3px;
    margin: 10px auto;
    width: 200px;
 }
/* .left-section .avatar {
    width: auto;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 20px;
}

.left-section .avatar img {
    width: auto;
    height: 80px;
    object-fit: cover;
} */

/* Sidebar CSS */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #D9D9D9;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1001;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
}

.sidebar.open {
    left: 0;
}

.sidebar .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2em;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.sidebar a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 1.2em;
    color: black;
    display: block;
    transition: background-color 0.3s ease;
}



/* Overlay for sidebar */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1000;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    .sticky-header {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .right-section {
        display: none;
    }

    .footer-section h3 {
        display: none;
    }

    .footer-section.copyright {
        display: flex;
        width: 30%;
    }

    .social-icons {
        margin-right: 20px;
    }

    .social-icon-l {
        display: none;
    }

    .location {
        display: none;
    }
}

/* Footer CSS */
.footer {
    background-color: #0B1F31;
    color: white;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
}

.footer-section {
    text-align: center;
    display: flow;
    flex-direction: column;
    align-items: center;
}

.footer-section h3 {
    font-size: 1.2em;
    margin: 0;
    margin-bottom: 5px;
    color: white;
}

.footer-section p {
    margin: 3px 0;
    font-size: 1em;
    margin-bottom: 5px;
}

.social-icons {
    display: inline-flex;
    align-items: center;
}

.social-icon {
    width: 30px;
    height: auto;
    margin-right: 5px;
}

.social-icon-l {
    width: 30px;
    height: auto;
    margin-right: 5px;
}

.social-icons a {
    color: white;
    text-decoration: none;
    margin: 0 5px;
    font-size: 1.5em;
}

.social-icons a:hover {
    color: white;
}

.footer-section.contact {
    text-align: left;
}

.footer-section.copyright {
    box-shadow: 0px -2px 5px rgba(255, 255, 255, 0.8);
    background-color: #0B1F31;
    color: white;
    width: 30%;
    padding: 5px;
    text-align: center;
}