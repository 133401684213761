body {
    background-color: #0B1F31;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 0 20px;
  }
  
  .not-found-logo {
    width: 150px;
    margin-bottom: 1rem;
  }
  
  .not-found-title {
    font-size: 3rem;
    color: #DAC625;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found-home-link,
  .not-found-contact-link {
    text-decoration: none;
    color: #ffffff;
    background-color: #DAC625; 
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1.3rem;
    transition: background-color 0.3s ease;
    margin: 0.5rem;
  }
  
  .not-found-home-link:hover,
  .not-found-contact-link:hover {
    background-color: #e6b800; 
  }