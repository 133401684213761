.container {
    width: 90%;
    padding: 20px;
    background-color: #0B1F31;
    text-align: center;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.square-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.square {
    border-bottom: 3px solid #DAC625;
    border-top: 3px solid #DAC625;
    background-color: #0538642a;
    width: 16%;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(11, 31, 49, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.app-squares, .app-squares-n {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    gap: 20px;
}

.square-row {
    display: flex;
}

.square:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transform: scale(1.2);
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    overflow: hidden;}

.upload-button {
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    background-color: #0056b3;
    color: white;
    position: absolute;
    bottom: -10px;
    left: 65px;
    transform: translateX(-50%);
}

.download-button {
    background-color: transparent;
    font-size: 22px;
    /* font-weight: bold; */
    color: white;
    text-decoration: underline;
}

.upload-button-container {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.confirm {
    margin: 5px;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    background-color: green;
    color: white;
    font-size: 22px;
    font-weight: bold;
    border:1px white ;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .confirm:hover {
    background-color: #76c76b;
  }
  
  .confirm:active {
    background-color: #5daa5d;
  }
  .clear {
    background-color: blue;
    color: white;
    border: 1px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
  }
.upload-button:hover {
    background-color: #0073a8;
}

.drag-drop-container {
    width: 40%;
    background-color: #0B1F31;
    padding: 20px;
    border-radius: 8px;
    border: 2px dashed white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.drag-drop-area {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.drag-drop-area:hover {
    background-color: #ddd;
}

.drag-drop-text {
    font-size: 16px;
    color: #555555;
    margin-top: 10px;
}

.drag-drop-image {
    max-width: 120px;
    max-height: 120px;
    margin-bottom: 10px;
}
.container h1 {
    font-size: 56px;
    color: #DAC625;
    margin-bottom: 20px;
    text-align: center;
}
.confirm-upload-btn {
    padding: 10px 20px; 
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none; 
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.confirm-upload-btn:hover {
    background-color: #45a049;
}
.success-message {
    color: green;
    font-size: 16px;
    margin-top: 10px;
}

.error-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
}

.video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
}

.video-container video {
    width: 100%;
    height: auto;
}
.app-icon {
    width: auto;
    height: 150px;
}
.upload-progress-container {
    margin-top: 10px;
    text-align: center;
    margin-left: 10px;
}
.app-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-done-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.upload-done-info>.icon{
    width: 20px;
    height: 20px;
    fill: white;
    background-color: #4CAF50;
    padding: 3px;
    border-radius: 50%;
    margin-left: 10px;

}
.reportName:hover{
    cursor: pointer;
}
.pdfFileAndDownload{
    display: flex;
    flex-direction: column;
}
.pdfFileAndDownload>a{
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 30px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    text-decoration: none;
    max-width: fit-content;
    word-break: break-word
}
.pdfFileAndDownload>a:hover{
    text-decoration: underline;
}
.pdfFileAndDownload>a>.icon{
    width: 20px;
    height: 20px;
    fill: white;
    margin-right: 5px;
}
.fileselect-btn{
    font-weight: bold;
    padding: 10px 100px;
    background-color: #DAC625;
    color: white;
    font-size: 22px;
    border:1px white ;
    border-radius: 25px;
    cursor: pointer;
}
.fileselect-btn:hover{
    background-color: #394146;
}
@media screen and (max-width: 1000px) {
    .report-list-container{
        width:100%;
    }
    .app-content {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }
    .container {
        width: 95%; 
        padding-bottom: 20%;
        padding-top: 60px;
        overflow-y: scroll;
    }

    .app-squares {
        margin-bottom: 20px;
        margin-top: 10px;
        height: fit-content;
    }

    .square {
        width: 90%; 
        margin: 5px 10px;
        flex: 0 0 auto;
    }

    .drag-drop-container {
        width: 90%;
    }
    .drag-drop-area {
        padding: 5%;
    }
    .confirm-upload-btn {
        margin-top: 2%;
        border-radius: 10%;
        border: 10px;
        background-color: green;
        padding: 2%;
        font-size: 18px;
    }
}
