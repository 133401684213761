.report-list-container {
  background-color: transparent;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 60%;
  height: fit-content;
  margin: 15px;
}

.report-list-container h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.report-item > h3 {
  overflow-wrap: anywhere;
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.search-input {
  margin-bottom: 0;
  padding: 12px 50px 12px 40px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  transition: border-color 0.3s;
  background-color: #D9D9D9;
  
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 26px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
}

.sort-buttons {
  display: flex;
  align-items: center;
}

.sort-icon {
  cursor: pointer;
  font-size: 24px;
  color: #DAC625;
  margin: 0 10px;
}

.sort-icon.active {
  color: #0B1F31;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
}

.video-container {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.video {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.5rem;
  color: #D9C524;
  margin-bottom: 10px;
}

.service-info {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
  font-weight: 500;
}

.details-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color: #0056b3;
}

.button-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.report-item {
  border-bottom: 2px solid black;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0px 2px 10px 0px #D9C524;
  padding: 10px;
  background-color: #0B1F31;
}

.reportName {
  padding-left: 25px;
  color: #D9C524;
  text-decoration: none;
  font-weight: bold;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left center;
}

.reportName:hover {
  text-decoration: underline;
  color: #0056b3;
}

.button-container button {
  background-color: #D9C524;
  color: #0B1F31;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10%;
  font-weight: bold;
  font-size: large;
}

.button-container button:hover {
  background-color: #0056b3;
}

.service {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 5px;
}

.service .date-info{
  font-size: medium;
  /* text-decoration: underline; */
  font-weight: 600;
  color:#D9D9D9;
}