.file-upload-circles {
  text-align: center;
}

.header {
  font-size: 48px;
  color: #DBC607;
  margin-bottom: 30px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.circle-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
}

.circle-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.circle {
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
}

.dashed-inner {
  width: 130px;
  height: 130px;
  border: 3px dashed white;
}

.solid-middle {
  width: 100px;
  height: 100px;
  border: 3px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: anywhere;
}

.dashed-outer {
  width: 150px;
  height: 150px;
  border: 3px dashed white;

}

.circle-wrapper:hover .circle {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.circle span {
  position: absolute;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  width: 60%;
  border: dashed 1px white;
  background-color: #0B1F31;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
}

.close-modal {
  position: absolute;
  top: 0px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #DAC625;
}
.modal-overlay .drag-drop-area>div{
  width: 100%;
}
.modal-overlay .fileselect-btn{
  padding: 10px 0px;
  width: 95%;
}

@media (max-width: 600px) {
  .header {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .circle-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .circle-wrapper {
    width: 120px;
    height: 120px;
  }

  .dashed-inner {
    width: 100px;
    height: 100px;
    border: 3px dashed white;
  }

  .solid-middle {
    width: 80px;
    height: 80px;
    border: 3px solid white;
  }

  .dashed-outer {
    width: 120px;
    height: 120px;
    border: 3px dashed white;
  }

  .circle span {
    font-size: 18px;
  }
  .modal-content{
    width: 100%;
    max-width: 95%;
  }
  
  
  
}