.container-fluid {
  width: 90%;
  padding: 20px;
  background-color: transparent;
  text-align: center;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.container {
  padding-top: 20px;
}

.display-1 {
  color: #DAC625;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5%;
}

.text-primary {
  color: white;
  text-align: center;
}
.text-center {
  font-size: 18px;
  color: white;
  margin-top: 20px;
}

.slick-slider {
  width: 80%;
  margin: 0 auto;
}

.slick-dots {
  position: absolute;
  width: 100%;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.testimonial-slide {
  text-align: center;
}

.testimonial-image {
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 10px 10px 10px #6b5a5a;
  
}

.testimonial-name {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  color: #DAC625;
}

@media only screen and (max-width: 768px) {
  .display-1 {
    margin-top: 15%;
  }
  .slick-slider {
    width: 90%;
    margin: 0 auto;
  }
  .testimonial-image {
    width: 380px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border-radius: 0px;
    box-shadow: none;
  }
  .container-fluid {
    width: 95%;
    padding: unset;
    background-color: transparent;
    text-align: center;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    margin: 20px auto;
  }
  .selected-files>ul>li{
    overflow-wrap: break-word;
  }
}