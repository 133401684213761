.map-container {
  align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
    width: 90%;
}
.cover-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}
.cover-image-space{
  width: 100vw;
}
.cover-image {
  width: 100%;
  max-width: 1600px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.table-holder {
  border: 3px solid;
    margin: 20px 0;
    width: -webkit-fill-available;
    overflow-x: auto;
    padding: 15px;
}

.prediction-heading {
  color: #99D18B;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}

.table-heading-temperature,
.table-heading-dissolvedOxygen,
.table-heading-chlorophyll,
.table-heading-salinity {
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
}

.table-heading-temperature { background-color: #4acfd9; }
.table-heading-dissolvedOxygen { background-color: #ff7070; }
.table-heading-chlorophyll { background-color: #7DB26F; }
.table-heading-salinity { background-color: #BCAA4A; }

.table-temperature { border-color: #4acfd9; }
.table-dissolvedOxygen { border-color: #FF7070; }
.table-chlorophyll { border-color: #7DB26F; }
.table-salinity { border-color: #BCAA4A; }

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th, .data-table td {
  padding: 10px;
  text-align: center;
  color: white;
  border: 1px solid #555;
}

.data-table th {
  background-color: #1e293b;
  color: rgb(126, 179, 218);
  text-transform: uppercase;
  font-size: 16px;
}

.data-table td {
  background-color: #1e293b;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.data-table tr:nth-child(even) td {
  background-color: rgba(0, 0, 0, 0.1);
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.collapse-btn, .download-btn  {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #36454F;
  opacity: 70%;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 15px;
}

.collapse-btn:hover, .download-btn:hover {
  scale: 1.1;
}
.current-image {
  width: 400px;
}
.current-data-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #2c3e50;
  border-radius: 10px;
  color: white;
}

.current-data-heading {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

.image-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  background-color: #081a28;
}
.image-row>div>h4{
  text-align: center;
  margin:20px 0px;
  font-size: 20px;
}
.image-row>div>img{
  width: 18vw;
}

.placeholder-image {
  width: 200px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border: 2px white dashed;
}

@media (max-width: 768px) {
  .table-holder {
    overflow-x: auto;
    padding: 10px;
  }

  .data-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    font-size: 12px;
  }

  .data-table th, .data-table td {
    padding: 6px;
    text-align: left;
    font-size: 14px;
  }

  .data-table th {
    font-size: 12px;
  }

  .table-heading-temperature,
  .table-heading-dissolvedOxygen,
  .table-heading-chlorophyll,
  .table-heading-salinity {
    max-width: 250px;
  }

  .download-btn,
  .collapse-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .current-data-heading {
    font-size: 18px;
  }

  .image-row {
    display: grid;
    gap: 10px;
  }
.current-image {
  width: 200px;
}
  .placeholder-image {
    width: 100px;
    margin: 10px;
  }
}
