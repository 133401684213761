:root {
    --primary-bg-color: #0B1F31;
    --text-color: #fff;
    --error-color: #DC3545;
    --font-family-base: 'Roboto', sans-serif;
    --input-padding: 10px;
}
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-bg-color);
    padding: 20px;
}
.login-container, .further-container {
    opacity: 80%;
    width: 350px;
    background-color: var(--primary-bg-color);
    padding: 40px;
    border-radius: 8px;
    border: 1px solid var(--text-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px; /* Added to separate the login and further sections */
}
.login-box {
    text-align: center;
    color: var(--text-color);
}
.login-box h2 {
    margin-bottom: 20px;
    font-size: 2em;
}
.form-group {
    position: relative;
}
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="text"] {
    width: 100%;
    padding: 20px 20px 20px 70px; /* Adjust for consistent padding */
    border-radius: 40px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    background-color: #2A3E52;
    color: var(--text-color);
    font-size: 16px;
    transition: border-color 0.3s;
}
.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus,
.form-group input[type="text"]:focus {
    border-color: #DAC625;
    outline: none;
}
.form-group input[type="checkbox"] {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    width: auto;
}
.remember {
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: var(--text-color);
}
button[type="submit"] {
    width: 100%;
    background: transparent;
    color: var(--text-color);
    padding: 10px 20px;
    border: 1px solid var(--text-color);
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
    font-size: 1.3em;
    font-weight: bold;
}
button[type="submit"]:hover {
    background-color: #0056B3;
    color: var(--text-color);
}
.baza-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: var(--primary-bg-color);
    color: #DAC625;
    border: 2px solid #DAC625;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}
.baza-button:hover {
    background-color: #DAC625;
    color: var(--primary-bg-color);
}
.error-message {
    color: var(--error-color);
    margin-bottom: 10px;
}
.heading-container {
    text-align: center;
}
.heading {
    font-size: 2.5rem;
    color: var(--text-color);
    margin-bottom: 10px;
    font-family: var(--font-family-base);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease-in-out;
}
.sub-heading {
    font-size: 3.5rem;
    color: var(--text-color);
    margin-top: -10px;
    font-family: var(--font-family-base);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease-in-out;
}
.sub-heading-f {
    font-size: 1rem;
    color: var(--text-color);
    font-family: var(--font-family-base);
    letter-spacing: 0.5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease-in-out;
}
.sub-heading-f a {
    color: #DAC625;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}
.sub-heading-f a:hover {
    color: #C4A324;
}
.sub-heading-p {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-top: -10px;
    font-family: var(--font-family-base);
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease-in-out;
    text-wrap: balance;
}
.sub-heading-p a {
    color: #DAC625;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}
.sub-heading-p a:hover {
    color: #C4A324;
}
.yellow-line {
    width: 100px;
    height: 3px;
    background-color: #DAC625;
}
.input-container {
    position: relative;
    width: 100%;
}
.input-icon {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    color: var(--text-color);
    font-size: 24px;
    background-color: rgba(201, 200, 200, 0.781);
    border-radius: 50%;
    padding: 8px;
    height: 50px;
    width: 50px;
    transition: background-color 0.3s, color 0.3s;
}
.input-icon:hover {
    background-color: rgba(201, 200, 200, 1);
    color: var(--primary-bg-color);
}
.password-input-container {
    position: relative;
}
.password-input-field {
    width: 100%;
    padding: 20px 20px 20px 70px; /* Adjust for consistent padding */
    border-radius: 40px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    background-color: #2A3E52;
    color: var (--text-color);
    font-size: 16px;
}
.toggle-password {
    position: absolute;
    right: 15px;
    top: 40%;
    transform: translateY(-50%);
    border: none;
    background: none;
    color: var(--text-color);
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    outline: none;
    z-index: 10;
}
.baza-text {
    color: var(--text-color);
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    text-wrap: balance;
}
.baza-text a {
    color: #DAC625;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}
.baza-image {
    display: block;
    margin: 0 auto;
    width: 250px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}
.baza-image:hover {
    transform: scale(1.1);
}
.services-image {
    display: block;
    margin: 20px auto;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}
.services-image:hover {
    scale: 1.1;
}
.logo-bluedatab {
    display: block;
    margin: 0 auto 20px;
    max-width: 300px;
    height: auto;
}
.logo-bluedatab:hover {
    transform: scale(1.2);
}
@media screen and (max-width: 768px) {
    .heading {
        font-size: 2rem;
    }
    .login-container, .further-container {
        width: 320px;
    }
    .sub-heading {
        font-size: 2.5rem;
    }
    .sub-heading-p {
        font-size: 1.1rem;
    }
    .sub-heading-f {
        font-size: 1rem;
        margin-top: 10px;
    }
    .yellow-line {
        margin: 2px auto 10px auto;
    }
    .services-image {
        max-width: 300px;
    }
}